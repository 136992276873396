// Facebookみたいに改行に合わせて伸びるフォーム
// http://qiita.com/mizchi/items/c39fd4b532f702c22d18
/* eslint no-param-reassign: ["error", { "props": false }] */
export default function (el, maxHeight) {
  let adjustedHeight = el.clientHeight;
  if (!maxHeight || maxHeight > adjustedHeight) {
    adjustedHeight = Math.max(el.scrollHeight, adjustedHeight);
    if (maxHeight) {
      adjustedHeight = Math.min(maxHeight, adjustedHeight);
    }
    if (adjustedHeight > el.clientHeight) {
      el.style.height = `${adjustedHeight}px`;
    }
  }
}
