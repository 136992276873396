import { Controller } from '@hotwired/stimulus';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';

export default class extends Controller {
  static values = {
    id: String,
  };

  connect() {
    this.element.setAttribute('id', this.idValue);

    const lightbox = new PhotoSwipeLightbox({
      gallery: `#${this.idValue}`,
      children: 'a',
      zoom: true,
      bgOpacity: 0.9,
      showHideAnimationType: 'fade',
      secondaryZoomLevel: 2.5,
      pswpModule: PhotoSwipe,
    });
    lightbox.init();

    /*
      画像を読み込んだらPhotoSwipeの設定値を入れて表示を更新する。
      現状PhotoSwipeはpswpWidthとpswpHeightに固定値が必要で、動的に画像サイズを反映する仕組みが無い。
      SEE: https://github.com/dimsemenov/PhotoSwipe/issues/741
    */
    lightbox.on('loadComplete', ({ content, slide }) => {
      const linkElement = content.data.element;

      // refreshSlideContent後のloadComplete以降はスキップする
      if (linkElement.dataset.loaded === '1') return;

      const imageElement = content.element;
      linkElement.dataset.pswpWidth = imageElement.naturalWidth;
      linkElement.dataset.pswpHeight = imageElement.naturalHeight;
      linkElement.dataset.loaded = '1';
      content.instance.refreshSlideContent(slide.index);
    });
  }
}
