import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Button } from 'react-bootstrap';
import screenfull from 'screenfull';
import Swipeable from 'react-swipeable';
import polyglot from '../../../application/lib/polyglot';

export default class SlidePlayer extends Component {
  state = {
    index: 0,
    direction: null,
    continuePlayback: false,
  };

  player = React.createRef();

  audioElement = document.createElement('audio');

  constructor(props) {
    super(props);

    this.audioElement.crossOrigin = 'anonymous';
    this.audioElement.setAttribute('preload', 'metadata');
    this.audioElement.addEventListener('ended', () => {
      const { audioPlaying } = this.state;
      if (audioPlaying) {
        this.setState({ audioPlaying: false });
        const { index, audioIndex, continuePlayback } = this.state;
        if (!this.isLastIndex() && index === audioIndex) {
          this.handleSwipedLeft();
          if (continuePlayback) {
            const { audios } = this.props;
            this.playAudio(audios[index + 1]);
          }
        }
      }
    });
  }

  componentWillUnmount() {
    this.stopAudio();
  }

  handlePrev = () => {
    const { index } = this.state;
    this.setState({
      index: index - 1,
      direction: 'prev',
    });
  };

  handleNext = () => {
    const { index } = this.state;
    this.setState({
      index: index + 1,
      direction: 'next',
    });
  };

  handleSwipedLeft = () => {
    if (this.isLastIndex()) return;

    const { index } = this.state;
    this.setState({
      index: index + 1,
      direction: 'next',
    });
  };

  handleSwipedRight = () => {
    if (this.isFirstIndex()) return;

    const { index } = this.state;
    this.setState({
      index: index - 1,
      direction: 'prev',
    });
  };

  handleFullScreen = () => {
    screenfull.toggle(this.player.current);
  };


  continuePlaybackOnChange = () => {
    this.setState({ continuePlayback: !this.state.continuePlayback });
  }

  isFirstIndex() {
    const { index } = this.state;
    return index === 0;
  }

  isLastIndex() {
    const { images } = this.props;
    const { index } = this.state;

    return images.length - 1 === index;
  }

  stopAudio() {
    this.audioElement.pause();
    this.audioElement.setAttribute('src', '');

    this.setState({
      audioPlaying: false,
      audioIndex: null,
    });
  }

  async playAudio(audio) {
    // NOTE: HTMLAudioElementのsrc差し替え＆load()で再生する音声ファイルの切り替えができる
    this.audioElement.setAttribute('src', audio);
    this.audioElement.load();
    this.audioElement.play();

    this.setState({
      audioPlaying: true,
      audioIndex: this.state.index,
    });
  }

  renderAudioControlBar(index) {
    const { audios } = this.props;
    const { audioPlaying, audioIndex } = this.state;
    const audio = audios[index];
    if (audioPlaying) {
      return (
        <div className="slide-player__audio-control-bar">
          <small>
            {polyglot.t('js.slide_player.slide')}
            {audioIndex + 1}
            {polyglot.t('js.slide_player.comment_on_playing')}
          </small>
          <span className="slide-player__audio-control-button" onClick={() => this.stopAudio()}>
            <i className="fas fa-stop" />
          </span>
        </div>
      );
    }
    if (!audio) {
      return (
        <small>
          {polyglot.t('js.slide_player.no_comment')}
        </small>
      );
    }
    return (
      <div className="slide-player__audio-control-bar">
        <small>
          {polyglot.t('js.slide_player.slide')}
          {index + 1}
          {polyglot.t('js.slide_player.comment')}
        </small>
        <span className="slide-player__audio-control-button" onClick={() => this.playAudio(audios[index])}>
          <i className="fas fa-play" />
        </span>
      </div>
    );
  }

  render() {
    const { images } = this.props;
    const { index, direction } = this.state;

    const items = images.map(image => (
      <Carousel.Item key={image} className="slide-player__item">
        <img src={image} alt="" />
      </Carousel.Item>
    ));

    return (
      <div className="slide-player" ref={this.player}>
        <Swipeable onSwipedLeft={this.handleSwipedLeft} onSwipedRight={this.handleSwipedRight}>
          <Carousel
            interval={null}
            prevIcon={null}
            nextIcon={null}
            activeIndex={index}
            direction={direction}
            indicators={false}
            controls={false}
          >
            {items}
          </Carousel>
        </Swipeable>
        <div className="slide-player__controlbar">
          <Button
            bsStyle="link"
            className="slide-player__controlbar-fullscreen"
            onClick={this.handleFullScreen}
            disabled={!screenfull.enabled}
          >
            <i className="fas fa-arrows-alt" />
          </Button>
          <span className="slide-player__controlbar-nav">
            <div className="slide-player__audio-control">
              { this.renderAudioControlBar(index) }
            </div>
            <label>
              <input
                className="slide-player__audio-repeat-checkbox"
                type="checkbox"
                checked={this.state.continuePlayback}
                onChange={this.continuePlaybackOnChange}
              />
              <span className="slide-player__audio-repeat-text">{polyglot.t('js.slide_player.play_continue')}</span>
            </label>
            <div className="slide-player__page-control">
              <Button bsStyle="link" onClick={this.handlePrev} disabled={this.isFirstIndex() || this.state.continuePlayback}>
                <i className="fas fa-caret-left fa-2x" />
              </Button>
              <span className="slide-player__controlbar-nav-pages">
                {index + 1}
                /
                {images.length}
              </span>
              <Button bsStyle="link" onClick={this.handleNext} disabled={this.isLastIndex() || this.state.continuePlayback}>
                <i className="fas fa-caret-right fa-2x" />
              </Button>
            </div>
          </span>
        </div>
      </div>
    );
  }
}

SlidePlayer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  audios: PropTypes.arrayOf(PropTypes.string).isRequired,
};
