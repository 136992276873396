import { Controller } from '@hotwired/stimulus';
import { durationSecondsToString } from '../shared/lib/duration';

export default class extends Controller {
  static targets = ['durationText', 'durationValue'];

  connect() {
    this.durationTextTarget.innerHTML = durationSecondsToString(0);
  }

  selected(event) {
    const audio = new Audio([URL.createObjectURL(event.target.files[0])]);
    audio.addEventListener('loadedmetadata', () => {
      const flooredDuration = Math.round(audio.duration); // ruby側のdurationに合わせて四捨五入
      this.durationTextTarget.innerHTML = durationSecondsToString(flooredDuration);

      this.durationValueTarget.value = flooredDuration;

      // 本来はdispatchするだけで良さそうだが、何故かdispatchEventを呼ばないと発火しない
      const updatedEvent = this.dispatch('updated');
      event.target.dispatchEvent(updatedEvent);
    });
  }
}
