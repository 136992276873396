import $ from 'jquery';

const EXAM = {};

EXAM.answerInitialize = () => {
  let limitTime;
  let startTime;
  const ENTER_KEY = 13;
  const TAB_KEY = 9;
  const $question = $('.js-examination-animation-block');
  const timeLimitationRequired = $question.data('timeLimitation');

  if (timeLimitationRequired) {
    startTime = $question.data('startTime') * 1000;
    limitTime = $question.data('limitMinutes') * 60 * 1000;
    EXAM.calculateTimeLeft(startTime, limitTime);
  }

  EXAM.disableNext();
  $('.js-examination-animation-block').fadeTo('slow', 1);

  $('.js-step-btn').on('click', () => $('.js-examination-animation-block').fadeTo('slow', 0));

  $('body').on('keydown', (ev) => {
    let $nextCheckBox;
    let $checked;
    const $nextBtn = $('.js-btn-next');

    if (ev.which === ENTER_KEY) {
      if ($nextBtn.length) {
        $nextBtn[0].click();
      }
    } else if (ev.which === TAB_KEY && !EXAM.timeover() && $('.js-answer-check-label').length > 0) {
      $checked = $('.js-examination-answer-input:checked');
      $checked.prop('checked', false);
      $nextCheckBox = $checked
        .parent()
        .next()
        .find('input');

      if ($nextCheckBox.length) {
        $nextCheckBox.prop('checked', true);
      } else {
        $('.js-examination-answer-input:first').prop('checked', true);
      }

      ev.preventDefault();
    }
  });

  $('.js-answer-check-label').on('click', (event) => {
    let $input;
    event.preventDefault();

    if (!EXAM.timeover()) {
      $input = $(event.currentTarget)
        .parent()
        .find('.js-examination-answer-input');

      if (EXAM.isSingleChoiseQuestion()) {
        $('.js-question-choice .js-examination-answer-input').each((_i, element) => {
          $(element).prop('checked', false);
        });

        $input.prop('checked', true);
        $('.js-examination-form').submit();
      } else if (EXAM.validateCheckCount()) {
        $input.prop('checked', false);
      } else {
        $input.prop('checked', !$input.prop('checked'));

        if (EXAM.validateCheckCount()) {
          $('.js-examination-form').submit();
        }
      }

      EXAM.disableNext();
    }
  });

  $('.js-examination-pending').on('click', () => EXAM.disableNext());

  return $('.js-examination-answer-input').on('keyup keypress', () => EXAM.disableNext());
};

EXAM.validateCheckCount = () => $('.js-question-choice').data('correctAnswerCount')
  <= $('.js-examination-answer-input:checked').length;

EXAM.isSingleChoiseQuestion = () => $('.js-question-choice').data('correctAnswerCount') === 1;

EXAM.calculateTimeLeft = (startTime, limitTime) => {
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const elapsedTime = currentTime - startTime;
  let leftMinutes = Math.floor((limitTime - elapsedTime) / 1000 / 60);
  let leftSeconds = Math.floor(((limitTime - elapsedTime) / 1000)
    - (leftMinutes * 60)); // prettier-ignore

  if (elapsedTime > limitTime) {
    leftMinutes = 0;
    leftSeconds = 0;
    $('.js-timeover-alert').show();
    $('.js-btn-prev').addClass('disabled');
    $('.js-btn-next').addClass('disabled');
    $('.js-btn-done').hide();
    $('.js-btn-timeover').show();
    $('.js-answer-check').prop('disabled', true);
    $('.js-examination-answer-input:checked').prop('checked', false);
    $('.js-examination-answer-input').attr('disabled', true);
    $('.js-examination-pending').attr('disabled', true);
  } else {
    setTimeout(() => {
      EXAM.calculateTimeLeft(startTime, limitTime);
    }, 1000);
  }

  $('.js-left-minutes').html(leftMinutes);
  return $('.js-left-seconds').html(leftSeconds);
};

EXAM.timeover = () => parseInt($('.js-left-minutes').html(), 10) === 0;

EXAM.disableNext = () => {
  if (EXAM.validateSelectAnswered() || EXAM.validateTextAnswered()) {
    $('.js-btn-next').addClass('disabled');
  }
  $('.js-btn-next').removeClass('disabled');
};

EXAM.validateSelectAnswered = () => EXAM.answerInputType() === 'select' && !EXAM.validateCheckCount() && !EXAM.pending();

EXAM.validateTextAnswered = () => EXAM.answerInputType() === 'text'
  && $('.js-examination-answer-input').val().length === 0
  && !EXAM.pending();

EXAM.answerInputType = () => $('.js-examination-answer').data('inputType');

EXAM.pending = () => $('.js-examination-pending').prop('checked');

$(document).on('turbo:load', () => {
  if ($('.js-examination-form').length > 0) {
    EXAM.answerInitialize();
  }
});
