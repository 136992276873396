import { Controller } from '@hotwired/stimulus';
import { durationSecondsToString } from '../shared/lib/duration';

export default class extends Controller {
  static targets = ['duration', 'totalDuration', 'alertArea'];

  static values = {
    max: { type: Number, default: 10 },
    maxAlert: String,
    latestFileDuration: Number,
    otherFilesDuration: Number,
  }

  connect() {
    const currentTotal = this.latestFileDurationValue + this.otherFilesDurationValue;
    this.alertTotal(currentTotal);
  }

  calculate() {
    const total = parseInt(this.durationTarget.value, 10) + this.otherFilesDurationValue;
    this.alertTotal(total);
  }

  alertTotal(total) {
    this.totalDurationTarget.innerHTML = durationSecondsToString(total);
    if (total > this.maxValue) {
      this.alertAreaTarget.innerHTML = this.maxAlertValue;
    }
  }
}
