import tablesort from 'tablesort';

// NOTE: sortFunction が モジュール読み込みに対応していない為
window.Tablesort = tablesort;
require('tablesort/src/sorts/tablesort.number');

export default function () {
  Array.from(document.querySelectorAll('.js-tablesort')).forEach((element) => {
    tablesort(element);
  });

  // NOTE: data-sort-orderが無効となっているので強制的に並び替えている
  // https://github.com/tristen/tablesort/issues/151
  const defaultDescSortHeader = document.querySelector('[data-sort-order="desc"]');
  if (defaultDescSortHeader) defaultDescSortHeader.click();
}
