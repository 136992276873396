import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'fileName'];

  connect() {
    this.fieldTarget.addEventListener('change', this.changed.bind(this));
  }

  changed() {
    if (this.hasFileNameTarget) {
      if (this.fileNameTarget instanceof HTMLInputElement) {
        this.fileNameTarget.value = this.fieldTarget.files[0]?.name || '';
      } else {
        this.fileNameTarget.textContent = this.fieldTarget.files[0]?.name || '';
      }
    }
  }
}
