const {
  env, phrases, locale,
} = window.APP.rails;

const user = () => window.APP.rails.user;

const flash = () => window.APP.rails.flash;

const mentionNames = () => window.APP.rails.mention;

const snsConfigs = () => window.APP.rails.sns;

const isProduction = () => env === 'production';

const isStaging = () => env === 'staging';

const isDevelopment = () => env === 'development';

const isTest = () => env === 'test';

const isUserSignedIn = () => !!user();

export {
  env,
  phrases,
  locale,
  user,
  flash,
  mentionNames,
  snsConfigs,
  isProduction,
  isStaging,
  isDevelopment,
  isTest,
  isUserSignedIn,
};
