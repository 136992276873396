import React from 'react';
import PropTypes from 'prop-types';

export default function TeamMessageInput(props) {
  return (
    <div className="team-message-input">
      <form className="team-message-input__form" onSubmit={event => props.dispatch('postMessage', event)}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={props.value}
            data-test-id="team-message-input"
            onChange={event => props.dispatch('inputMessage', event.target.value)}
          />
          <span className="input-group-btn">
            <button type="submit" className="btn btn-warning">
              送信
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}

TeamMessageInput.propTypes = {
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
