import WebpackerReact from 'webpacker-react';
import TeamMessagesApp from '../components/team_messages/team_messages_app_container';
import DateCountdown from '../components/date_countdown/date_countdown';
import Countup from '../components/countup/countup';
import AnswerForm from '../../features/question/containers/answer_form';
import Datepickr from '../components/datepicker/datepicker';
import GreetingModalContainer from '../components/greeting_modal/greeting_modal_container';
import SlidePlayer from '../../shared/components/slide_player/slide_player';
import NoteImageGallery from '../components/note_image_gallery/note_image_gallery';

export default function () {
  WebpackerReact.setup({
    Datepickr,
    DateCountdown,
    TeamMessagesApp,
    Countup,
    AnswerForm,
    GreetingModalContainer,
    SlidePlayer,
    NoteImageGallery,
  });
}
