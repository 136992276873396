import $ from 'jquery';

const DISPLAY_DELAY_MILLISECOND = 1000;

const initialize = () => {
  const $answerModal = $('.js-answer-modal');

  if ($answerModal.length === 0) {
    return;
  }

  $answerModal.fadeIn('slow', () => setTimeout(() => $answerModal.fadeOut('slow'), DISPLAY_DELAY_MILLISECOND));
};

$(document).on('turbo:load', initialize);
