import React from 'react';
import PropTypes from 'prop-types';

export default class Countup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.startVal,
    };

    this.countDown = props.startVal > props.endVal;
    this.countUp = this.countUp.bind(this);
  }

  componentDidMount() {
    const { wait } = this.props;
    this.timerId = setTimeout(this.start.bind(this), wait);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    cancelAnimationFrame(this.requestId);
  }

  start() {
    this.requestId = requestAnimationFrame(this.countUp);
  }

  calcCount(progress) {
    const { startVal, endVal, duration } = this.props;

    /* eslint no-mixed-operators: [0] */
    if (this.countDown) {
      const frameVal = startVal - (startVal - endVal) * (progress / duration);
      return Math.max(frameVal, endVal);
    }

    const frameVal = startVal + (endVal - startVal) * (progress / duration);
    return Math.min(frameVal, endVal);
  }

  countUp(timestamp) {
    const { duration, isFloat } = this.props;

    if (!this.startTime) {
      this.startTime = timestamp;
    }
    const progress = timestamp - this.startTime;
    const calculatedCount = this.calcCount(progress);

    // 小数値の場合はとりあえず小数第一位までの考慮
    const displayedCount = isFloat
      ? parseFloat(Math.floor(calculatedCount * 10)) / 10 : parseInt(calculatedCount, 10);
    this.setState({ count: displayedCount }, () => {
      if (progress < duration) {
        this.requestId = requestAnimationFrame(this.countUp);
      }
    });
  }

  render() {
    const { className } = this.props;
    const { count } = this.state;
    return <span className={className}>{count}</span>;
  }
}

Countup.propTypes = {
  endVal: PropTypes.number.isRequired,
  startVal: PropTypes.number,
  wait: PropTypes.number,
  duration: PropTypes.number,
  className: PropTypes.string,
  isFloat: PropTypes.bool,
};

Countup.defaultProps = {
  startVal: 0,
  wait: 2000,
  duration: 2000,
  className: '',
  isFloat: false,
};
