import React from 'react';
import PropTypes from 'prop-types';
import { parse, distanceInWordsToNow, format as dateFormat } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';

const formatTime = (date, format) => {
  if (typeof format === 'function') {
    return format(date);
  }

  return dateFormat(date, format);
};

export default function Time({ value, format, relative }) {
  const date = parse(value);
  const machineReadable = dateFormat(date, 'YYYY-MM-DDTHH:mm:ssZ');
  const humanReadable = relative
    ? distanceInWordsToNow(date, { locale: jaLocale })
    : formatTime(date, format);

  return <time dateTime={machineReadable}>{humanReadable}</time>;
}

Time.propTypes = {
  value: PropTypes.string.isRequired,
  relative: PropTypes.bool,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

Time.defaultProps = {
  relative: false,
};
