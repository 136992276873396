import $ from 'jquery';

const toggleEmployeeSelect = () => {
  if ($('.js-status-radio:checked').val() === 'employee') {
    $('.js-career-select, .js-course-select')
      .prop('disabled', true)
      .children('option')
      .prop('selected', false);
  } else {
    $('.js-career-select, .js-course-select').prop('disabled', false);
  }
};

const initialize = () => {
  toggleEmployeeSelect();
  $('.js-status-radio').on('change', () => toggleEmployeeSelect());
};

$(document).on('turbo:load', () => {
  if ($('.assesment_users .simple_form').length > 0) {
    initialize();
  }
});
