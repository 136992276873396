import { Controller } from '@hotwired/stimulus';
import Moveable from 'moveable';

export default class extends Controller {
  static targets = ['moveable', 'container'];

  connect() {
    const moveable = new Moveable(this.containerTarget, {
      target: this.moveableTarget,
      // If the container is null, the position is fixed. (default: parentElement(document.body))
      container: this.containerTarget,
      draggable: true,
      resizable: true,
      scalable: true,
      rotatable: true,
      warpable: true,
      // Enabling pinchable lets you use events that
      // can be used in draggable, resizable, scalable, and rotateable.
      pinchable: true, // ["resizable", "scalable", "rotatable"]
      origin: true,
      keepRatio: true,
      // Resize, Scale Events at edges.
      edge: false,
      throttleDrag: 0,
      throttleResize: 0,
      throttleScale: 0,
      throttleRotate: 0,
      renderDirections: [],
    });

    moveable.on('drag', ({
      target, transform,
      left, top, right, bottom,
      beforeDelta, beforeDist, delta, dist,
      clientX, clientY,
    }) => {
      // TODO コンテナ外へ移動できないようにする
      target.style.left = `${left}px`;
      target.style.top = `${top}px`;
    }).on('dragEnd', ({
      target, isDrag, clientX, clientY,
    }) => {
      // 本来はdispatchするだけで良さそうだが、何故かdispatchEventを呼ばないと発火しない
      const dragEndEvent = this.dispatch('dragEnd', {
        detail: {
          target, isDrag, clientX, clientY,
        },
      });
      this.moveableTarget.dispatchEvent(dragEndEvent);
    });
  }
}
