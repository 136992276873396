import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import TeamMessages from './team_messages';
import TeamMessageInput from './team_message_input';

export default function TeamMessagesApp(props) {
  return (
    <div className="panel-body team-messages-panel">
      <TeamMessages
        dispatch={props.dispatch}
        messages={props.messages}
        participations={props.participations}
        currentParticipationId={props.currentParticipationId}
        topicLinkDisabled={props.topicLinkDisabled}
      />
      <TeamMessageInput dispatch={props.dispatch} value={props.messageValue} />
    </div>
  );
}

TeamMessagesApp.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(Iterable).isRequired,
  participations: PropTypes.instanceOf(Iterable).isRequired,
  currentParticipationId: PropTypes.number.isRequired,
  messageValue: PropTypes.string.isRequired,
  topicLinkDisabled: PropTypes.bool.isRequired,
};
