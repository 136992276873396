import React from 'react';
import MicroContainer from 'react-micro-container';
import { shuffle } from 'lodash';
import AnswerForm from '../components/answer_form';

export default class AnswerFormContainer extends MicroContainer {
  constructor(props) {
    super(props);

    this.state = {
      answers: [],
      questionSortingChoices: shuffle(props.questionSortingChoices),
    };
  }

  componentDidMount() {
    this.subscribe({
      changeAnswers: this.handleChangeAnswers,
    });
  }

  handleChangeAnswers(answers) {
    this.setState({
      answers,
    });
  }

  render() {
    return <AnswerForm dispatch={this.dispatch} {...this.props} {...this.state} />;
  }
}
