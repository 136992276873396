import React, { PureComponent } from 'react';
import GreetingModal from './greeting_modal';
import ajax from '../../../shared/lib/ajax';
import { notifySuccess } from '../../../shared/lib/notify';
import polyglot from '../../lib/polyglot';

export default class GreetingModalContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      modalIsOpen: true,
    };

    this.handleUpdateMessage = this.handleUpdateMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.close.bind(this);
  }

  handleUpdateMessage(message) {
    this.setState({ message });
  }

  async handleSubmit(event) {
    event.preventDefault();
    await this.post();
    this.close();
    notifySuccess(polyglot.t('js.greeting.sent'));
  }

  post() {
    const { url } = this.props;
    const { message } = this.state;

    return ajax({
      url,
      type: 'POST',
      data: {
        team_message: { body: message },
      },
    });
  }

  close() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <GreetingModal
        {...this.props}
        {...this.state}
        handleUpdateMessage={this.handleUpdateMessage}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}
