import introJs from 'intro.js';

document.addEventListener('turbo:load', () => {
  // NOTE: turbolinks対策のためにDOMで判定していたが今はもう必要ないかも
  // NOTE: railsが吐き出すエラーページだと document.body が存在しないケースもある？
  // https://app.bugsnag.com/sonicgarden/mametore/errors/5b0c02f3f5adc4001b364826?filters%5Bevent.since%5D%5B0%5D=30d&filters%5Berror.status%5D%5B0%5D=open&pivot_tab=app.release_stage
  if (!(document.body && document.body.hasAttribute('data-intro-namespace'))) return;

  const namespace = document.body.getAttribute('data-intro-namespace');

  // NOTE: 対象外のチュートリアルを除外
  const elements = document.querySelectorAll('[data-intro-namespace]');
  let targetElementCount = 0;
  Array.from(elements).forEach((element) => {
    if (namespace !== element.getAttribute('data-intro-namespace')) {
      element.removeAttribute('data-intro');
    } else {
      targetElementCount += 1;
    }
  });

  // NOTE: intro.jsの固定ヘッダ対策
  // https://github.com/usablica/intro.js/issues/123
  const fixedElements = Array.from(document.querySelectorAll('.js-intro-disable-fixed'));
  fixedElements.forEach((element) => {
    element.classList.add('intro-disable-fixed');
  });

  const showBullets = targetElementCount > 1;
  introJs()
    .setOptions({
      showStepNumbers: false,
      showBullets,
    })
    .onexit(() => {
      fixedElements.forEach((element) => {
        element.classList.remove('intro-disable-fixed');
      });
    })
    .start();
});
