import { Application } from '@hotwired/stimulus';

import TextareaAutogrow from 'stimulus-textarea-autogrow';

import AudioFileInputController from './audio_file_input_controller';
import CanvasUploadFormController from './canvas_upload_form_controller';
import ColorPickerController from './color_picker_controller';
import FileFieldController from './file_field_controller';
import LightboxController from './lightbox_controller';
import MoveableController from './moveable_controller';
import SlideSourceImageEditorController from './slide_source_image_editor_controller';
import SortableSlideSourceImagesController from './sortable_slide_source_images_controller';
import TotalDurationController from './total_duration_controller';
import RemoveValueController from './remove_value_controller';

const application = Application.start();
// application.debug = true;
window.Stimulus = application;

application.register('textarea-autogrow', TextareaAutogrow);

application.register('audio-file-input', AudioFileInputController);
application.register('canvas-upload-form', CanvasUploadFormController);
application.register('color-picker', ColorPickerController);
application.register('file-field', FileFieldController);
application.register('lightbox', LightboxController);
application.register('moveable', MoveableController);
application.register('slide-source-image-editor', SlideSourceImageEditorController);
application.register('sortable-slide-source-images', SortableSlideSourceImagesController);
application.register('total-duration', TotalDurationController);
application.register('remove-value', RemoveValueController);
