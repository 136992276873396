import $ from 'jquery';

const refreshButtonClass = () => {
  const button = document.querySelector('.js-team-register-button');
  if (!button) {
    return;
  }

  const isHide = document.querySelectorAll('.js-team-register-checkbox:checked').length === 0;
  button.classList.toggle('hidden', isHide);
};

$(document)
  .on('change', '.js-team-register-checkbox', ({ currentTarget }) => {
    refreshButtonClass();
    currentTarget.closest('tr').classList.toggle('active', currentTarget.checked);
  })
  .on('turbo:load', refreshButtonClass);
