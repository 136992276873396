import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AnswerSorting from '../../../components/question/answer_sorting';
import AnswerSortingImage from '../../../components/question/answer_sorting_image';
import AnswerSelectImage from '../../../components/question/answer_select_image';

const answerComponents = {
  select_image: AnswerSelectImage,
  sorting: AnswerSorting,
  sorting_image: AnswerSortingImage,
};

export default function AnswerForm({
  answers,
  inputName,
  dispatch,
  questionSortingChoices,
  questionImageChoices,
  correctAnswers,
  inputType,
}) {
  const AnswerComponent = answerComponents[inputType];
  const inputs = answers.map((answer, index) => (
    <input
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      type="hidden"
      name={inputName}
      value={answer}
    />
  ));

  return (
    <Fragment>
      {inputs}
      <AnswerComponent
        questionSortingChoices={questionSortingChoices}
        questionImageChoices={questionImageChoices}
        onChange={(values) => {
          dispatch('changeAnswers', values);
        }}
        answers={answers}
        correctAnswers={correctAnswers}
      />
    </Fragment>
  );
}

AnswerForm.propTypes = {
  questionSortingChoices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    number: PropTypes.number,
  })).isRequired,
  questionImageChoices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    pictureUrl: PropTypes.string.isRequired,
  })).isRequired,
  dispatch: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputName: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  correctAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
