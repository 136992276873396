import $ from 'jquery';
import videojs from 'video.js';
import videojsInitializeWithButtons from '../../shared/pages/videojs_initializer';

videojs.options.hls.overrideNative = true;

const showVideoModal = ({ currentTarget, relatedTarget }) => {
  const $modal = $(currentTarget);
  const $link = $(relatedTarget);
  const $loading = $modal.find('.js-loading');
  const $modalBody = $modal.find('.js-modal-body');

  $modal.find('.js-modal-title').text($(relatedTarget).data('title'));
  $loading.show();
  $modalBody.html('');

  $.ajax({
    type: 'GET',
    url: $link.data('url'),
  }).done((html) => {
    $loading.hide();
    $modalBody.html(html);

    videojsInitializeWithButtons('js-videojs-player');
  });
};

$(document)
  .on('show.bs.modal', '#js-videojs-modal', showVideoModal)
  .on('hidden.bs.modal', '#js-videojs-modal', () => {
    videojs('js-videojs-player').dispose();
  });
