import React from 'react';
import PropTypes from 'prop-types';

const AnswerSortingImageButton = ({
  pictureUrl, body, selectedNumber, disabled, onClick,
}) => (
  <button
    className={`btn-link sorting-image-choices-answer ${selectedNumber > 0 ? 'is-selected' : ''}`}
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    <img className="sorting-image-choices-answer__image" src={pictureUrl} alt={body} />
    {selectedNumber > 0 && (
      // https://github.com/FortAwesome/Font-Awesome/issues/563#issuecomment-36090361
      <span className="fa-stack sorting-image-choices-answer__number">
        <i className="fas fa-circle fa-stack-2x" />
        <i className="fa fa-inverse fa-stack-1x">{selectedNumber}</i>
      </span>
    )}
  </button>
);

AnswerSortingImageButton.propTypes = {
  pictureUrl: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  selectedNumber: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

AnswerSortingImageButton.defaultProps = {
  selectedNumber: null,
};

const buildNewAnswers = (answers, body) => {
  const index = answers.indexOf(body);
  if (index === -1) {
    return [...answers, body];
  }

  return [...answers.slice(0, index), ...answers.slice(index + 1)];
};

export default function AnswerSortingImage({
  questionSortingChoices,
  answers,
  correctAnswers,
  onChange,
}) {
  const choiceImages = questionSortingChoices.map(({ id, body, pictureUrl }) => {
    const index = answers.indexOf(body);
    const selectedNumber = index > -1 ? index + 1 : null;
    return (
      <AnswerSortingImageButton
        key={id}
        pictureUrl={pictureUrl}
        body={body}
        selectedNumber={selectedNumber}
        disabled={selectedNumber === null && answers.length >= correctAnswers.length}
        onClick={() => {
          onChange(buildNewAnswers(answers, body));
        }}
      />
    );
  });

  return <div className="image-mw-100-content">{choiceImages}</div>;
}

AnswerSortingImage.propTypes = {
  questionSortingChoices: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      pictureUrl: PropTypes.string.isRequired,
      number: PropTypes.number,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  correctAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
