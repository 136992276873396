import $ from 'jquery';

const { google } = window;

const drawUsageChart = () => {
  let data = $('.js-usage-chart').data('chart');

  if (data.length < 1) {
    return;
  }

  data.unshift(['', '利用率(%)']);
  data = google.visualization.arrayToDataTable(data);

  const options = {
    title: '利用率(%)',
    colors: ['#A9D12D'],
    legend: 'none',

    hAxis: {
      textStyle: {
        fontSize: 12,
      },
    },

    vAxis: {
      minValue: 0,
      maxValue: 100,
    },
  };

  const chart = new google.visualization.AreaChart($('#js-usage-chart').get(0));
  chart.draw(data, options);
};

const initialize = () => {
  if ($('#js-usage-chart').length < 1 || !(google != null)) {
    return;
  }

  google.charts.load('current', {
    packages: ['corechart'],
  });

  google.charts.setOnLoadCallback(drawUsageChart);
};

$(document).on('turbo:load', initialize);
