import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Row, Col, FormGroup, FormControl, Button,
} from 'react-bootstrap';
import nl2br from 'react-nl2br';
import GreetingButton from './greeting_button';
import polyglot from '../../lib/polyglot';

export default function GreetingModal({
  title,
  description,
  modalIsOpen,
  handleUpdateMessage,
  handleSubmit,
  message,
  isGreetingButtonsEnabled,
  otherUserTeamMessages,
}) {
  const greetingButtons = (
    <Row className="m-t-3">
      { /*
        MEMO: チームレベル（成熟度）ポイントを付与する際、挨拶内容がjs.greeting.type1~4と異なる場合はポイントが増加する仕様がある。
              以下の固定文言を追加したらTeamMessageも修正すること。
        */ }
      <Col xs={6}>
        <GreetingButton text={polyglot.t('js.greeting.type1')} handleUpdateMessage={handleUpdateMessage} />
      </Col>
      <Col xs={6}>
        <GreetingButton text={polyglot.t('js.greeting.type2')} handleUpdateMessage={handleUpdateMessage} />
      </Col>
      <Col xs={6}>
        <GreetingButton text={polyglot.t('js.greeting.type3')} handleUpdateMessage={handleUpdateMessage} />
      </Col>
      <Col xs={6}>
        <GreetingButton text={polyglot.t('js.greeting.type4')} handleUpdateMessage={handleUpdateMessage} />
      </Col>
    </Row>
  );

  let formControlBody;
  if (isGreetingButtonsEnabled) {
    formControlBody = (
      <FormControl
        type="text"
        value={message}
        onChange={({ target: { value } }) => {
          handleUpdateMessage(value);
        }}
        required
      />
    );
  } else {
    formControlBody = (
      <FormControl
        componentClass="textarea"
        value={message}
        onChange={({ target: { value } }) => {
          handleUpdateMessage(value);
        }}
        rows={3}
      />
    );
  }

  let otherUserTeamMessageElements;
  if (otherUserTeamMessages.length > 0) {
    otherUserTeamMessageElements = otherUserTeamMessages.map(teamMessage => (
      <div className="team-messages__team-message-w-100" key={teamMessage.id}>
        <div className="team-messages__team-message-user">
          <img
            className="img-circle team-messages__team-message-user-icon"
            src={teamMessage.pictureUrl}
            alt={teamMessage.userName}
            title={teamMessage.userName}
          />
        </div>
        <div className="team-messages__team-message-content">
          <div className="team-messages__team-message-content-name text-muted small">
            {teamMessage.userName}
          </div>
          <div className="team-messages__team-message-content-body">
            {nl2br(teamMessage.body)}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <Modal show={modalIsOpen}>
      <Modal.Header>
        <Modal.Title className="text-center">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">{nl2br(description)}</p>
        { isGreetingButtonsEnabled && greetingButtons }
        <form className="clearfix m-t-2" onSubmit={handleSubmit}>
          <FormGroup>
            { formControlBody }
          </FormGroup>
          <Button type="submit" bsStyle="warning" className="pull-right">
            {polyglot.t('js.greeting.send')}
          </Button>
        </form>
        { otherUserTeamMessageElements }
      </Modal.Body>
    </Modal>
  );
}

GreetingModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  handleUpdateMessage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isGreetingButtonsEnabled: PropTypes.bool.isRequired,
  otherUserTeamMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
};
