import $ from 'jquery';
import { start } from 'activestorage';

start();

$(document).on('change', '.js-activestorage-form', ({ currentTarget }) => {
  // NOTE: currentTarget.submit() だと submitイベントが発生しない
  // 動的にボタンを追加すると意図通りに動作しないため、hiddenのsubmitボタンをクリックする
  // https://github.com/rails/rails/blob/a4798570bfbff542521f3fe02671669e402421ae/activestorage/app/javascript/activestorage/ujs.js#L20
  currentTarget.querySelector('input[type=submit]').click();
});
