import React from 'react';
import MicroContainer from 'react-micro-container';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import TeamMessagesApp from './team_messages_app';
import ajax from '../../../shared/lib/ajax';
import polyglot from '../../lib/polyglot';

const REFRESH_INTERVAL = 15 * 1000;

export default class TeamMessagesAppContainer extends MicroContainer {
  constructor(props) {
    super(props);

    const { participations, currentParticipationId, topicLinkDisabled } = props;
    this.state = {
      currentParticipationId,
      topicLinkDisabled,
      messages: new Map(),
      participations: new Map(
        participations.map(participation => [participation.id, participation]),
      ),
      messageValue: '',
    };
  }

  componentDidMount() {
    this.subscribe({
      inputMessage: this.handleInputMessage,
      postMessage: this.handlePostMessage,
      destroyMessage: this.handleDestroyMessage,
    });

    this.appendMessages(this.props.messages);
    this.refreshTimer = setTimeout(this.refresh.bind(this), REFRESH_INTERVAL);
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimer);
  }

  handleInputMessage(value) {
    this.setState({ messageValue: value });
  }

  async handlePostMessage(event) {
    event.preventDefault();
    if (this.state.messageValue.length === 0) return;

    this.setState({ messageValue: '' });
    const teamMessage = await this.postMessage();
    // NOTE: lastId更新前に前回からの差分をリクエストしておく
    this.refresh();
    this.appendMessages([teamMessage]);
  }

  async handleDestroyMessage(id) {
    // eslint-disable-next-line no-alert
    if (!window.confirm(polyglot.t('js.team_message.delete_confirm'))) return;

    const message = this.state.messages.get(id);
    await ajax({ type: 'DELETE', url: message.url });
    this.setState({ messages: this.state.messages.delete(id) });
  }

  postMessage() {
    return ajax({
      type: 'POST',
      url: this.props.url,
      data: {
        team_message: { body: this.state.messageValue },
      },
    });
  }

  getLastId() {
    if (this.state.messages.count() === 0) {
      return 0;
    }
    return this.state.messages.keySeq().max();
  }

  async refresh() {
    clearTimeout(this.refreshTimer);

    const { messages, participations } = await ajax({
      type: 'GET',
      url: this.props.url,
      cache: false,
      data: {
        last_id: this.getLastId(),
      },
    });

    this.setState({ participations: new Map(participations.map(p => [p.id, p])) });
    this.appendMessages(messages);
    this.refreshTimer = setTimeout(this.refresh.bind(this), REFRESH_INTERVAL);
  }

  appendMessages(messages) {
    const newMessages = new Map(
      messages.map((message) => {
        const participation = this.state.participations.get(message.participationId);
        return [message.id, Object.assign({}, message, { participation })];
      }),
    );
    this.setState({ messages: this.state.messages.merge(newMessages).sortBy(m => m.id) });
  }

  render() {
    return <TeamMessagesApp dispatch={this.dispatch} {...this.state} />;
  }
}

TeamMessagesAppContainer.propTypes = {
  currentParticipationId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  topicLinkDisabled: PropTypes.bool.isRequired,
};
