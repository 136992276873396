import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default function TeamMessageReaders(props) {
  if (props.participations.isEmpty()) return null;

  const icons = props.participations.valueSeq().map((participation) => {
    const tooltip = (
      <Tooltip id="tooltip">{participation.user.userName}</Tooltip>
    );
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip} key={participation.id}>
        <img
          className="img-circle team-messages__team-readers-icon"
          src={participation.user.pictureUrl}
          alt={participation.user.userName}
        />
      </OverlayTrigger>
    );
  });

  return (
    <div className="team-messages__team-readers">
      {icons}
    </div>
  );
}

TeamMessageReaders.propTypes = {
  participations: PropTypes.instanceOf(Iterable).isRequired,
};
