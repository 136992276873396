import $ from 'jquery';
import { Textcomplete, Textarea } from 'textcomplete';
import { mentionNames } from './rails';

const convertToMentionNames = names => (names ? names.map(name => name.replace(/(^\s+)|(\s+$|@)/g, '')) : '');

const factoryMentionStrategy = () => {
  const memberships = convertToMentionNames(mentionNames());
  return {
    id: 'mention',
    // eslint-disable-next-line no-control-regex
    match: /(^|\s)@(\w*|[^\x01-\x7E\xA1-\xDF]*)$/,
    search(term, callback) {
      const loweredTerm = term.toLowerCase();
      return callback(
        memberships.filter(membership => membership.toLowerCase().startsWith(loweredTerm)),
      );
    },
    replace(value) {
      return `$1@${value} `;
    },
  };
};

const setupTextcomplete = (textarea) => {
  const editor = new Textarea(textarea);
  const textcomplete = new Textcomplete(editor);

  textcomplete.register([factoryMentionStrategy()]);

  textcomplete.on('rendered', () => {
    if (textcomplete.dropdown.items.length > 0) {
      // Activate the first item by default.
      textcomplete.dropdown.items[0].activate();
    }
  });
};

$(document).on('focus', '.js-textcomplete', ({ currentTarget }) => {
  if (currentTarget.dataset.textcompleteLoaded) {
    return;
  }
  setupTextcomplete(currentTarget);

  // eslint-disable-next-line no-param-reassign
  currentTarget.dataset.textcompleteLoaded = true;
});
