import $ from 'jquery';

$(document).on('change', '.js-onchange-submit', ({ currentTarget }) => {
  // NOTE: currentTarget.submit() だと submitイベントが発生しない
  let button = currentTarget.querySelector('input[type=submit]');
  if (button) {
    const { disabled } = button;
    button.disabled = false;
    button.focus();
    button.click();
    button.disabled = disabled;
  } else {
    button = document.createElement('input');
    button.type = 'submit';
    button.style.display = 'none';
    currentTarget.appendChild(button);
    button.click();
    currentTarget.removeChild(button);
  }
});
