import React from 'react';
import PropTypes from 'prop-types';
import { isSameDay, format as dateFormat } from 'date-fns';
import Time from './time';

export default function DateTimeOrTime({ value, timeFormat, dateTimeFormat }) {
  const format = (date) => {
    if (isSameDay(date, new Date())) {
      return dateFormat(date, timeFormat);
    }

    return dateFormat(date, dateTimeFormat);
  };

  return <Time value={value} format={format} />;
}

DateTimeOrTime.propTypes = {
  value: PropTypes.string.isRequired,
  dateTimeFormat: PropTypes.string,
  timeFormat: PropTypes.string,
};

DateTimeOrTime.defaultProps = {
  dateTimeFormat: 'YYYY/MM/DD HH:mm',
  timeFormat: 'HH:mm',
};
