import React from 'react';
import PropTypes from 'prop-types';
import Livestamp from 'react-livestamp';
import polyglot from '../../lib/polyglot';

const renderStamp = ({
  days, hours, minutes, seconds,
}) => (
  <span className="react-livestamp">
    <strong>{polyglot.t('js.date_countdown.livestamp_format')}</strong>
  </span>
);

renderStamp.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
};

const renderExpired = () => (
  <span className="react-livestamp">
    <strong>
      {polyglot.t('js.date_countdown.initial_livestamp_format')}
    </strong>
  </span>
);

export default function DateCountdown({ end }) {
  return <Livestamp end={end} renderStamp={renderStamp} renderExpired={renderExpired} />;
}

DateCountdown.propTypes = {
  end: PropTypes.string.isRequired,
};
