import $ from 'jquery';
import { snsConfigs } from '../../shared/lib/rails';

const showSnsDialog = () => {
  const snsDialog = $('.js-sns');
  if (!(snsDialog.length)) return;

  // alert(document.getSelection().toString());
  const selection = document.getSelection();
  if (selection.toString().length > 0 && selection.rangeCount) {
    const range = selection.getRangeAt(0).cloneRange();
    const rect = range.getBoundingClientRect();
    const tooltipX = Math.floor(rect.left + rect.width / 2);
    const tooltipY = Math.floor(rect.top - 65 + $(window).scrollTop());
    const sns = snsConfigs();
    let hashTag = '';
    let refUrl = '';
    if (sns.twitter_hash_tag) hashTag = `&hashtags=${sns.twitter_hash_tag}`;
    if (sns.twitter_ref_url) refUrl = `&url=${sns.twitter_ref_url}`;

    $('.js-twitter').attr('href', encodeURI(`https://twitter.com/intent/tweet?text=${selection.toString()}${hashTag}${refUrl}`));
    snsDialog.show();
    snsDialog.offset({ top: tooltipY, left: tooltipX });
  } else {
    snsDialog.hide();
  }
};

$(document).on('mouseup', showSnsDialog);
