const INTERVAL = 500;

const waitForImageToLoad = (element, callback) => {
  const complete = Array.from(element.querySelectorAll('img')).every(img => img.complete);
  if (complete) {
    callback();
  } else {
    setTimeout(() => {
      waitForImageToLoad(element, callback);
    }, INTERVAL);
  }
};

export default function (element) {
  return new Promise((resolve) => {
    waitForImageToLoad(element, resolve);
  });
}
