import React, { PureComponent, Fragment } from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import ajax from '../../../shared/lib/ajax';
import polyglot from '../../../application/lib/polyglot';
import { Modal } from 'react-bootstrap';

export default class NoteImageGallery extends PureComponent {
  constructor(props) {
    super(props);

    this.imageFilesInput = React.createRef();
    this.handleSubmitImage = this.handleSubmitImage.bind(this);
    this.fileInputChanged = this.fileInputChanged.bind(this);

    this.state = {
      isInitialized: false,
      isLoading: false,
      noteImages: new Map(),
      showModal: false,
      currentModalImageUrl: '',
      isRegistrationButtonDisabled: true,
    };
  }

  async componentDidMount() {
    const { isUpdate } = this.props;

    if (isUpdate) {
      await this.loadNoteImages();
    }

    this.setState({
      isInitialized: true
    });
  }

  async loadNoteImages() {
    const { getNoteImagesPath } = this.props;
    const noteImages = await ajax({
      url: getNoteImagesPath,
      type: 'GET',
      cache: false,
    });

    this.setState({
      noteImages,
    });
  }

  async deleteNoteImage(noteImageId) {
    if (!window.confirm(polyglot.t('js.general.delete_confirm'))) return

    await ajax({
      url: this.viewingHistoryNoteImageUrl(noteImageId),
      type: 'DELETE',
      cache: false,
    });

    await this.loadNoteImages()
  }
  
  openModal(imageUrl) {
    this.setState({ 
      currentModalImageUrl: imageUrl,
      showModal: true,
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  fileInputChanged(event) {
    const { maxNoteImagesNumber } = this.props;

    const imageFilesNumber = this.imageFilesInput.current.files.length
    if (this.state.noteImages.length + imageFilesNumber > maxNoteImagesNumber) {
      alert(polyglot.t('js.viewing_history.training_note.note_images_max_number.alert', { max_number: maxNoteImagesNumber }))
      event.preventDefault()
      this.imageFilesInput.current.value = ''
      this.setState({ isRegistrationButtonDisabled: true })
    } else {
      this.setState({ isRegistrationButtonDisabled: (imageFilesNumber === 0) })
    }
  }

  async handleSubmitImage(event) {
    event.preventDefault()
    if (this.state.isRegistrationButtonDisabled) return

    this.setState({ isRegistrationButtonDisabled: true, isLoading: true })

    const { fileSelectFormName } = this.props

    const formData = new FormData();
    Array.from(this.imageFilesInput.current.files).forEach((file) => {
      formData.append(fileSelectFormName, file);
    })

    try {
      await ajax({
        url: this.viewingHistoryUrl(),
        type: 'PATCH',
        data: formData,
        processData: false,
        contentType: false,
      });
    
      await this.loadNoteImages()
    } catch (e) {
      // 想定するサーバ側のバリデーションエラーはトーストで表示されるため、ここでは何もしない
    }

    this.setState({ isRegistrationButtonDisabled: false, isLoading: false })
    this.imageFilesInput.current.value = ''
  }

  viewingHistoryUrl() {
    const { courseId, topicId } = this.props

    return `/courses/${courseId}/topics/${topicId}/viewing_history`
  }

  viewingHistoryNoteImageUrl(noteImageId) {
    const { courseId, topicId } = this.props

    return `/courses/${courseId}/topics/${topicId}/viewing_history/images/${noteImageId}`
  }

  render() {
    const { courseId, topicId, isUpdate, fileSelectFormName, maxNoteImagesNumber, maxNoteImageFileSize } = this.props
    const { noteImages, showModal, currentModalImageUrl, isRegistrationButtonDisabled, isInitialized, isLoading } = this.state;

    const noteImageElements = [];
    
    // チラつくので最初の画像取得が終わった後に表示する
    if (isInitialized && !isLoading) {
      if (noteImages.length > 0) {
        noteImages.forEach((noteImage) => {
          noteImageElements.push(
            <div
              key={noteImage.id}
              style={{
                width: '30%',
                padding: '5px',
                margin: '5px',
                backgroundColor: '#ffffff',
                border: '1px solid #cccccc',
                display: 'flex',
                flexFlow: 'column',
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <img src={noteImage.url} alt="Note image" style={{ maxWidth: '100%' }} onClick={() => this.openModal(noteImage.url)} />
              </div>
              <div
                className="m-t-1 text-center"
              >
                <span
                  className="btn btn-danger"
                  onClick={() => this.deleteNoteImage(noteImage.id)}>
                  削除
                </span>
              </div>
            </div>,
          );
        });
      } else {
        noteImageElements.push(
          <div key="js-no-image-element" className="w-100 text-muted text-center m-b-3">
            { polyglot.t('js.viewing_history.training_note.note_images_container_description') }
          </div>
        )
      }
    } else {
      noteImageElements.push(
        <div key="js-image-loading-element" className="w-100 m-b-3" style={{display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading
            type="spin"
            color="#337ab7"
          />
        </div>
      )
    }

    let noteImageUploadButton;
    if (isUpdate) {
      noteImageUploadButton = <a
        href='#'
        className="btn btn-warning"
        onClick={this.handleSubmitImage}
        disabled={isRegistrationButtonDisabled}
      >
        { polyglot.t('js.general.registration') }
      </a>
    }

    return (
      <Fragment>
        <Modal
          dialogClassName="Modal__Bootstrap modal-dialog entry__like-modal"
          show={showModal}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              { polyglot.t('js.viewing_history.note_images') }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass="modal-body entry__like-modal-body">
            <img src={ currentModalImageUrl } style={{ maxWidth: '100%' }}></img>
          </Modal.Body>
        </Modal>
        <p style={{ fontWeight: 700 }}>
          { polyglot.t('js.viewing_history.note_images') }
        </p>
        <ul style={{ margin: '5px auto' }}>
          <li>
            { polyglot.t('js.viewing_history.training_note.note_images_max_number.description', { max_number: maxNoteImagesNumber }) }
          </li>
          <li>
            { polyglot.t('js.viewing_history.training_note.note_image_max_file_size.description', { max_file_size: maxNoteImageFileSize }) }
          </li>
        </ul>
        <div style={{ display: 'flex', flexWrap: 'no-wrap' }}>
          <input
            type="file"
            className="form-control"
            multiple="multiple"
            accept="image/jpeg,image/png"
            name={fileSelectFormName}
            ref={this.imageFilesInput}
            onChange={this.fileInputChanged}
          />
          { noteImageUploadButton }
        </div>
        <div className="m-t-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {noteImageElements}
        </div>
      </Fragment>
    );
  }
}


NoteImageGallery.propTypes = {
  courseId: PropTypes.number.isRequired,
  topicId: PropTypes.number.isRequired,
  getNoteImagesPath: PropTypes.string.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  fileSelectFormName: PropTypes.string.isRequired,
  maxNoteImagesNumber: PropTypes.number.isRequired,
  maxNoteImageFileSize: PropTypes.string.isRequired,
};
