import $ from 'jquery';
import 'select2';
import fitToContent from '../lib/fit_to_content';

const FIT_CONTENT_MAX_HEIGHT = 500;

const initialize = () => {
  $('.js-tooltip').tooltip();
  $('.js-select2').select2();
  $('.js-select2-with-tags').select2({
    tags: true,
  });
  $('[data-toggle="popover"]').popover();
  $('.js-autofocus').trigger('focus');
  $('.js-auto-show-modal').modal('show');
};

$.fn.select2.defaults.apply({
  theme: 'bootstrap',
  placeholder: '',
  allowClear: true,
});

const $document = $(document);

$document.on('input', '.js-fit-content', ({ currentTarget }) => {
  const maxHeight = currentTarget.dataset.maxHeight
    ? parseInt(currentTarget.dataset.maxHeight, 10)
    : FIT_CONTENT_MAX_HEIGHT;
  fitToContent(currentTarget, maxHeight);
});

$document
  .on('turbo:load', initialize)
  .on('nested:fieldAdded', ({ field }) => field.find('.js-select2').select2());
