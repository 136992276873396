import React from 'react';
import PropTypes from 'prop-types';

export default function AnswerSelectImage({ questionImageChoices, answers, onChange }) {
  const choices = questionImageChoices.map(({ value, pictureUrl }, index) => {
    const id = `question_answer_${index}`;
    return (
      <div key={value} className="question-answer-select-image">
        <input
          type="radio"
          className="question-answer-select-image__input"
          name="answer"
          id={id}
          value={value}
          checked={answers[0] === value}
          onChange={() => {
            onChange([value]);
          }}
        />
        <label htmlFor={id} className="question-answer-select-image__label">
          <img src={pictureUrl} alt={value} className="question-answer-select-image__label-image" />
          <i className="fas fa-check-circle fa-2x question-answer-select-image__label-icon" />
        </label>
      </div>
    );
  });

  return <div>{choices}</div>;
}

AnswerSelectImage.propTypes = {
  questionImageChoices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      pictureUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
