import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { range } from 'lodash';

const ChoiceButton = ({ children, className, ...props }) => (
  <Button bsSize="large" className={`m-r-1 ${className}`} {...props}>
    {children}
  </Button>
);

ChoiceButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ChoiceButton.defaultProps = {
  className: '',
};

export default class AnswerSorting extends PureComponent {
  constructor(props) {
    super(props);

    // NOTE: 同一選択肢が複数存在するケースもある為、選択状態をidで保持している
    this.state = {
      selectedIds: [],
    };

    this.handleClickChoiceButton = this.handleClickChoiceButton.bind(this);
    this.handleClickAnsweredButton = this.handleClickAnsweredButton.bind(this);
  }

  handleClickChoiceButton({ id, body }) {
    this.props.onChange([...this.props.answers, body]);
    this.setState({
      selectedIds: [...this.state.selectedIds, id],
    });
  }

  handleClickAnsweredButton(index) {
    this.props.onChange([
      ...this.props.answers.slice(0, index),
      ...this.props.answers.slice(index + 1),
    ]);

    this.setState({
      selectedIds: [
        ...this.state.selectedIds.slice(0, index),
        ...this.state.selectedIds.slice(index + 1),
      ],
    });
  }

  render() {
    const { questionSortingChoices, correctAnswers, answers } = this.props;

    const choiceButtons = questionSortingChoices.map(({ id, body }) => (
      <ChoiceButton
        key={id}
        bsStyle="info"
        // NOTE: 選択肢が複数行表示になった時の為にマージン指定している
        className="m-t-1"
        disabled={this.state.selectedIds.includes(id) || answers.length >= correctAnswers.length}
        onClick={() => {
          this.handleClickChoiceButton({ id, body });
        }}
      >
        {body}
      </ChoiceButton>
    ));

    const answeredButtons = answers.map((answer, index) => (
      <ChoiceButton
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        bsStyle="success"
        onClick={() => {
          this.handleClickAnsweredButton(index);
        }}
      >
        {answer}
      </ChoiceButton>
    ));

    const blankAnswers = range(correctAnswers.length - answers.length).map(index => (
      <div key={index} className="sorting-choices-answers__blank_answer m-r-1" />
    ));

    return (
      <div className="sorting-choices-answers">
        <div>
          {answeredButtons}
          {blankAnswers}
        </div>
        <hr />
        <div className="test-choice-buttons">{choiceButtons}</div>
      </div>
    );
  }
}

AnswerSorting.propTypes = {
  questionSortingChoices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    number: PropTypes.number,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  correctAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
