import $ from 'jquery';
import { isString, isPlainObject } from 'lodash';
import { camelizeKeys, decamelizeKeys } from 'humps';

// NOTE: スネークケース、キャメルケースの変換を透過的に
export default function ajax({ url, data = null, ...options }) {
  // NOTE: jQueryのデフォルト動作だとurl未指定はカレントurlにリクエストされるが意図しないDELETE等が発生する可能性があって危険
  if (!url) {
    return Promise.reject(new Error('Url is empty.'));
  }

  // NOTE: jQueryのajaxを直接 async, awaitに書き換えた場合にtry catchが使えないのでPromiseでラップしている
  // TODO: 脱jQueryが終わったらaxios等のライブラリ導入を検討する
  return new Promise((resolve, reject) => {
    $.ajax({
      data: isPlainObject(data) ? decamelizeKeys(data) : data,
      url,
      ...options,
    })
      .then(result => (isString(result) ? result : camelizeKeys(result)))
      .then(resolve)
      .catch(reject);
  });
}
