import Sortable from 'stimulus-sortable';

export default class extends Sortable {
  static targets = ['input'];

  static values = {
    sort: { type: Boolean, default: true },
  }

  connect() {
    super.connect();

    document.querySelectorAll('.js-slide-builder-memo').forEach((element) => {
      element.disabled = true;
      element.style.display = 'none';
    });
  }

  get defaultOptions() {
    return {
      group: {
        name: 'slide-source-images',
        pull: 'clone',
        put: this.sortValue,
      },
      sort: this.sortValue,
      delay: 300,
      delayOnTouchOnly: true,
      onEnd: (event) => {
        if (event.from === event.to) return;

        const memoElement = event.item.querySelector('.js-slide-builder-memo');
        memoElement.disabled = false;
        memoElement.style.display = 'block';

        const positionElement = event.item.querySelector('.js-slide-builder-position');
        positionElement.value = event.newIndex + 1;
      },
    };
  }
}
