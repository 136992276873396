import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['canvas'];

  submit(event) {
    event.preventDefault();

    // fetch送信
    this.canvasTarget.toBlob((blob) => {
      const formData = new FormData(this.element);
      // TODO: nameはvalueで指定する
      formData.append('slide_source_image[image]', blob, Date.now());
      fetch(this.element.action, {
        method: this.element.method,
        body: formData,
      }).then((response) => {
        // TODO: エラー処理
        if (!response.ok) return new Error('error');
        window.location.href = response.url;
      }).catch((error) => {
        console.log(error);
      });
    }, 'image/png');
  }
}
