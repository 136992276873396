document.addEventListener('turbo:load', () => {
  const toggleTargets = document.querySelectorAll('.js-open-team-modal, .js-team-modal-bg, .js-team-modal-close');

  for (let i = 0; i < toggleTargets.length; i++) {
    toggleTargets[i].addEventListener('click', (e) => {
      const teamId = e.target.getAttribute('data-team-id');
      const teamModal = document.querySelector(`.team-modal-${teamId}`);
      teamModal.classList.toggle('team-modal-is-show');
    }, false);
  }
});
