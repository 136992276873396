import $ from 'jquery';

const calculateTimeLeft = (startTime, limitTime) => {
  const elapsedTime = Date.now() - startTime;
  let timeLeft = Math.ceil((limitTime - elapsedTime) / 1000 / 60);

  if (elapsedTime > limitTime) {
    timeLeft = 0;
    $('.js-timeover-alert').show();
    $('.js-btn-prev, .js-btn-next').addClass('disabled');
    $('.js-btn-timeover').show();
    $('.js-answer-radio').prop('disabled', true);
  } else {
    setTimeout(() => {
      calculateTimeLeft(startTime, limitTime);
    }, 1000);
  }

  $('.js-time-left').html(timeLeft);
};

const initialize = () => {
  const $question = $('.assesment-question');
  const startTime = $question.data('startTime') * 1000;
  const limitTime = $question.data('limitMinutes') * 60 * 1000;
  calculateTimeLeft(startTime, limitTime);
  $('.assesment-question__caption, .assesment-question__wording').fadeTo('slow', 1);

  $('.assesment-question__answer-step-btn').on('click', () => {
    $('.assesment-question__caption, .assesment-question__wording').fadeTo('slow', 0);
  });
};

$(document).on('turbo:load', () => {
  if ($('.assesment_answers .simple_form').length > 0) {
    initialize();
  }
});
