import initAnalytics from '../../shared/lib/analytics';
import initComponents from './components';
import '../../shared/lib/turbo';
import '../../shared/lib/ajax_error_handler';
import '../../shared/lib/intro';
import '../../shared/lib/onchage_submit_form';
import '../../shared/lib/auto_trim_input';
import '../../shared/lib/textcomplete';
import '../../shared/lib/movies';
import '../../shared/lib/activestorage_form';

export default function () {
  initAnalytics();
  initComponents();
  console.log('done');
}
