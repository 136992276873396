import $ from 'jquery';
import videojs from 'video.js';
import videojsInitializeWithButtons from '../../shared/pages/videojs_initializer';

$(document)
  .on('turbo:load', () => { videojsInitializeWithButtons('topic-movie'); })
  .on('turbo:before-visit', () => {
    // 別ページでプレーヤーが正常に見れなくなるため視聴した動画をページ訪問前に削除する
    if ($('#topic-movie').length > 0) {
      videojs('topic-movie').dispose();
    }
  })
  .on('contextmenu', '.js-disabled-contextmenu', event => event.preventDefault());
