import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import nl2br from 'react-nl2br';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import DateTimeOrTime from './date_time_or_time';
import TeamMessageReaders from './team_message_readers';
import polyglot from '../../lib/polyglot';

export default function TeamMessage(props) {
  const { message, message: { participation: { user } }, topicLinkDisabled } = props;
  const readParticipations = props.participations.filter(
    p => p.lastReadMessageId === message.id && p.id !== props.currentParticipationId,
  );
  const tooltip = <Tooltip id="tooltip">{user.userName}</Tooltip>;

  let destroyButton = null;
  if (props.isSelf) {
    destroyButton = (
      <span
        className="btn-link team-messages__team-message-info-destroy"
        role="button"
        onClick={() => props.dispatch('destroyMessage', message.id)}
        tabIndex={0}
      >
        {polyglot.t('js.general.delete')}
      </span>
    );
  }

  return (
    <div className="clearfix">
      <div className="clearfix">
        <div className={`team-messages__team-message${props.isSelf ? ' is-self' : ''}`}>
          <div className="team-messages__team-message-user">
            <OverlayTrigger placement="bottom" overlay={tooltip}>
              <img
                className="img-circle team-messages__team-message-user-icon"
                src={user.pictureUrl}
                alt={user.userName}
                title={user.userName}
              />
            </OverlayTrigger>
          </div>
          <div className="team-messages__team-message-content">
            <div className="team-messages__team-message-content-name text-muted small">
              {user.userName}
            </div>
            <div className="team-messages__team-message-content-body">
              {message.topicName && <a className="label label-warning" href={topicLinkDisabled ? '#' : `/courses/${message.courseId}/progresses?lecture_id=${message.lectureId}`}>{message.topicName}</a> }
              {message.messageType === 'knock_impression' && <span className="label label-danger">{polyglot.t('js.knock.name')}</span> }
              {message.userQuestionBody && (
              <div className="p-b-1">
                <span className="label label-warning m-r-1">{polyglot.t('js.user_question.name')}</span>
                { message.userQuestionBody }
              </div>
              )}
              {' '}
              {nl2br(message.body)}
            </div>
          </div>
          <div className="team-messages__team-message-info text-muted small text-nowrap">
            {destroyButton}
            <DateTimeOrTime value={message.createdAt} dateTimeFormat="MM/DD HH:mm" />
          </div>
        </div>
      </div>
      <TeamMessageReaders participations={readParticipations} />
    </div>
  );
}

TeamMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.shape({
    body: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    topicName: PropTypes.string,
    lectureId: PropTypes.number,
    courseId: PropTypes.number.isRequired,
    messageType: PropTypes.string.isRequired,
    userQuestionBody: PropTypes.string,
    participation: PropTypes.shape({
      lastReadMessageId: PropTypes.oneOfType([PropTypes.number]),
      user: PropTypes.shape({
        userName: PropTypes.string.isRequired,
        pictureUrl: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  isSelf: PropTypes.bool.isRequired,
  participations: PropTypes.instanceOf(Iterable).isRequired,
  currentParticipationId: PropTypes.number.isRequired,
  topicLinkDisabled: PropTypes.bool.isRequired,
};
