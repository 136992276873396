import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'rawBackground',
    'rawText',
    'view',
    'textArea',
    'text',
    'itemize',
    'fontSize',
    'align',
    'color',
    'lineHeight',
  ];

  static values = {
    src: String,
    displayHeight: { type: Number, default: 500 },
  };

  scale;

  connect() {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = this.srcValue;

    img.onload = () => {
      this.rawBackgroundTarget.width = img.naturalWidth;
      this.rawBackgroundTarget.height = img.naturalHeight;
      this.rawTextTarget.width = img.naturalWidth;
      this.rawTextTarget.height = img.naturalHeight;

      this.scale = parseFloat((img.naturalHeight / this.displayHeightValue).toFixed(1));

      // 表示用キャンバスを元画像のx分の1にする。
      const viewWidth = img.naturalWidth / this.scale;
      const viewHeight = img.naturalHeight / this.scale;

      const viewContext = this.viewTarget.getContext('2d');
      viewContext.canvas.width = viewWidth;
      viewContext.canvas.height = viewHeight;

      // 生データ側に元画像を描画しておく
      const rawBackgroundContext = this.rawBackgroundTarget.getContext('2d');
      rawBackgroundContext.drawImage(img, 0, 0);

      this.textTarget.style.display = 'none';

      this.applyText();
    };
  }

  showTextArea(event) {
    const parentRect = this.viewTarget.getBoundingClientRect();
    const parentTop = parentRect.top + window.scrollY;
    const parentLeft = parentRect.left + window.scrollX;

    const textTop = event.y + window.scrollY;
    const textLeft = event.x + window.scrollX;

    const textY = (textTop - parentTop);
    const textX = (textLeft - parentLeft);

    this.textTarget.style.top = `${textY}px`;
    this.textTarget.style.left = `${textX}px`;
    this.textTarget.style.display = 'block';
  }

  hideTextArea() {
    this.textTarget.style.display = 'none';
    this.applyText();
  }

  applyText() {
    // 生データ側のCanvasオブジェクトにテキストを描画
    const rawTextContext = this.rawTextTarget.getContext('2d');
    rawTextContext.clearRect(0, 0, rawTextContext.canvas.width, rawTextContext.canvas.height);
    rawTextContext.textAlign = this.alignTarget.value;
    rawTextContext.font = `${this.fontSizeTarget.value}px ＭＳ Ｐゴシック`; // TODO: フォント指定
    rawTextContext.fillStyle = this.colorTarget.value;

    const parentRect = this.viewTarget.getBoundingClientRect();
    const parentTop = parentRect.top + window.scrollY;
    const parentLeft = parentRect.left + window.scrollX;

    const textRect = this.textAreaTarget.getBoundingClientRect();
    const textTop = textRect.top + window.scrollY;
    const textLeft = textRect.left + window.scrollX;

    const rawY = (textTop - parentTop) * this.scale;
    const rawX = (textLeft - parentLeft) * this.scale;

    const viewContext = this.viewTarget.getContext('2d');

    // 元データにテキストを描画しておく
    // 最終的な画像生成はmergeを呼び出した際にのみ行う
    let textLines = this.textTarget.value.split(/\r?\n/);
    if (this.itemizeTarget.checked) textLines = textLines.map(line => `・${line}`);
    const lineHeight = this.fontSizeTarget.value * this.lineHeightTarget.value;
    // テキスト描画領域（矩形）の幅 = テキストの最大幅
    const maxTextWidth = textLines.reduce((acc, line) => {
      const measuredWidth = rawTextContext.measureText(line).width;
      return Math.max(acc, measuredWidth);
    }, 0);
    // CanvasのtextAlignは「描画開始位置の指定がテキストの左端・中央・右端のいずれかに対応する」という形式になっている。
    // よくあるスライド作成機能だと描画領域（矩形）に対して寄せる形なのでそれに合わせる。
    // https://developer.mozilla.org/ja/docs/Web/API/CanvasRenderingContext2D/textAlign
    let textStartX;
    if (this.alignTarget.value === 'center') {
      textStartX = rawX + (maxTextWidth / 2);
    } else if (this.alignTarget.value === 'right') {
      textStartX = rawX + maxTextWidth;
    } else {
      textStartX = rawX;
    }
    textLines.forEach((line, index) => {
      rawTextContext.fillText(line, textStartX, rawY + (lineHeight * (index + 1)));
    });
    this.textAreaTarget.style.width = `${maxTextWidth / this.scale}px`;
    this.textAreaTarget.style.height = `${lineHeight * (textLines.length / this.scale)}px`;

    // 表示用キャンバスに生データをコピー
    viewContext.drawImage(
      this.rawBackgroundTarget,
      0, 0, this.rawBackgroundTarget.width, this.rawBackgroundTarget.height,
      0, 0, viewContext.canvas.width, viewContext.canvas.height,
    );
    viewContext.drawImage(
      this.rawTextTarget,
      0, 0, this.rawTextTarget.width, this.rawTextTarget.height,
      0, 0, viewContext.canvas.width, viewContext.canvas.height,
    );
  }

  merge() {
    const distContext = this.rawBackgroundTarget.getContext('2d');

    distContext.drawImage(this.rawTextTarget, 0, 0);
  }
}
