/* eslint-disable class-methods-use-this */

import ColorPicker from 'stimulus-color-picker';

export default class extends ColorPicker {
  onSave(color) {
    super.onSave(color);
    const saveEvent = this.dispatch('save');
    this.element.dispatchEvent(saveEvent);
  }

  get componentOptions() {
    // SEE: https://github.com/Simonwep/pickr

    return {
      preview: true,
      hue: true,

      interaction: {
        input: true,
        save: true,
      },

      i18n: {
        'btn:save': '保存',
      },
    };
  }

  // You can override the swatches with this getter.
  // Here are the default options.
  // get swatches() {
  //   return [
  //     '#A0AEC0',
  //     '#F56565',
  //     '#ED8936',
  //     '#ECC94B',
  //     '#48BB78',
  //     '#38B2AC',
  //     '#4299E1',
  //     '#667EEA',
  //     '#9F7AEA',
  //     '#ED64A6',
  //   ];
  // }
}
