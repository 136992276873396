import $ from 'jquery';
import videojs from 'video.js';

function videojsCalculateTimeLeftCallback(player, remainingSecond, videoElementId) {
  if ($(`#${videoElementId}`).length === 0) return;

  const remainedSecond = player.remainingTime();

  if (remainedSecond < remainingSecond) {
    $('.js-complete-button').removeClass('is-disabled').removeAttr('disabled');
  }
  setTimeout(() => videojsCalculateTimeLeftCallback(player, remainingSecond, videoElementId), 1000);
}

const videojsInitializeWithButtons = (videoElementId) => {
  let player;
  const videoElement = document.getElementById(videoElementId);

  if (!videoElement) {
    return;
  }

  const $videoHistory = $('.js-video-history');
  const completed = $videoHistory.data('complete');
  const remainingSecond = $videoHistory.data('remainingSecond');
  const history = $videoHistory.data('history');

  const defaultOptions = {
    playbackRates: [1, 1.25, 1.5, 2],
    html5: {
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      hls: {
        overrideNative: true,
      },
    },
  };

  if (completed || history) {
    player = videojs(videoElementId, defaultOptions);
  } else {
    player = videojs(videoElementId, {
      ...defaultOptions,
      controlBar: {
        progressControl: false,
      },
    });

    if ($(`#${videoElementId}`).length > 0) {
      player.on('playing', () => videojsCalculateTimeLeftCallback(player, remainingSecond, videoElementId));
    }
  }

  player.on('error', () => {
    // NOTE: 自動リスタート
    // https://github.com/videojs/video.js/issues/1805#issuecomment-273671931
    if (player.error().code === 2) {
      const time = player.currentTime();
      player
        .error(null)
        .pause()
        .load()
        .currentTime(time)
        .play();
    }

    throw new Error(player.error().message);
  });

  $('.js-rewind-button').on('click', ({ currentTarget }) => {
    const rewindSecond = parseInt(currentTarget.dataset.rewindSecond, 10);
    player.currentTime(player.currentTime() - rewindSecond);
  });
};

export default videojsInitializeWithButtons;
