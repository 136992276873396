import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export default function GreetingButton({ text, handleUpdateMessage }) {
  return (
    <Button
      bsStyle="primary"
      block
      className="m-t-1"
      onClick={(e) => {
        handleUpdateMessage(e.currentTarget.textContent);
      }}
    >
      {text}
    </Button>
  );
}

GreetingButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleUpdateMessage: PropTypes.func.isRequired,
};
