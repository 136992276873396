import $ from 'jquery';
import { isTest } from './rails';
import { GA_TRACKING_ID } from './config.js.erb';

const eventTrack = () => {
  $(document).on('click', '[data-event-category]', ({ currentTarget }) => {
    const { eventCategory, eventAction } = currentTarget.dataset;
    window.ga('send', 'event', {
      eventCategory,
      eventAction,
    });
  });
};

export default function () {
  if (isTest()) return;

  /* eslint-disable */
  window.ga =
    window.ga ||
    function() {
      (window.ga.q = window.ga.q || []).push(arguments);
    };
  window.ga.l = +new Date();
  /* eslint-enable */

  window.ga('create', GA_TRACKING_ID, 'auto');
  window.ga('send', 'pageview');

  eventTrack();
}
