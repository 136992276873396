import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import { branch, renderComponent } from 'recompose';
import polyglot from '../../lib/polyglot';

const isMobile = () => /Android|Mobile/.test(navigator.userAgent);

const NativeDatepicker = ({ value, ...props }) => (
  <input type="date" defaultValue={value} {...props} />
);

NativeDatepicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

NativeDatepicker.defaultProps = {
  value: '',
};

const Datepicker = ({ min, options, ...props }) => (
  <Flatpickr options={{ locale: Japanese, minDate: min, ...options }} {...props} />
);

Datepicker.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Datepicker.defaultProps = {
  type: 'date',
  placeholder: polyglot.t('js.datepicker.select_date'),
  value: '',
  min: null,
  options: {},
};

// NOTE: 本来はFlatpicker側でモバイル端末の場合はネイティブ表示にしてくれるはずだけど、意図通りに動かないので自前で振り分けている
const withMobileCheck = branch(isMobile, renderComponent(NativeDatepicker), component => component);

export default withMobileCheck(Datepicker);
