import $ from 'jquery';
import { throttle } from 'lodash';
import polyglot from '../lib/polyglot';

const { google } = window;

const drawTeamRankingChart = () => {
  const $element = $('#js-team-ranking-chart');

  if ($element.length < 1) {
    return;
  }

  let data = $element.data('chart').slice(0);
  const height = data.length * 100;

  data.unshift([
    'Element',
    polyglot.t('js.lectures.point'),
    {
      role: 'style',
    },
    {
      role: 'annotation',
    },
  ]);

  data = google.visualization.arrayToDataTable(data);

  const options = {
    title: polyglot.t('js.lectures.team_ranking'),
    height,
    width: $element.width(),
    titleX: polyglot.t('js.lectures.member_average_point'),

    legend: {
      position: 'none',
    },

    hAxis: {
      minValue: 0,
      textStyle: {
        color: '#888888',
      },
    },

    vAxis: {
      textStyle: {
        fontSize: 20,
      },
    },
  };

  const chart = new google.visualization.BarChart($element.get(0));
  chart.draw(data, options);
};

const drawTeamContributionChart = () => {
  let color;
  const $element = $('#js-team-contribution-chart');

  if ($element.length < 1) {
    return;
  }

  const data = $element.data('chart').slice(0);
  const userId = $element.data('id');
  const slices = {};
  const colors = ['#2266AF', '#3F7BBC', '#5D92CA', '#81ABD7', '#A7C5E4'];

  // eslint-disable-next-line no-restricted-syntax
  for (const [index, value] of data.entries()) {
    if (value[2] === userId) {
      slices[index] = {
        color: '#A9D12D',
      };
    } else {
      color = (() => {
        if (colors.length > 0) {
          return colors.shift();
        }
        return '#A7C5E4';
      })();

      slices[index] = {
        color,
      };
    }

    if (value[1] < 0) {
      value[1] = 0;
    }
  }

  data.unshift(['Element', polyglot.t('js.lectures.point'), 'UserId']);
  const dataTable = google.visualization.arrayToDataTable(data);

  const options = {
    title: polyglot.t('js.lectures.team_contribution'),
    pieSliceText: 'label',
    slices,
    width: $element.width(),
  };

  const chart = new google.visualization.PieChart($element.get(0));
  chart.draw(dataTable, options);
};

const initialize = () => {
  if (google == null) {
    return;
  }

  google.charts.load('current', {
    packages: ['corechart'],
  });

  google.charts.setOnLoadCallback(() => {
    drawTeamRankingChart();
    drawTeamContributionChart();
  });
};

$(document).on('turbo:load', initialize);
$(window).on('resize', throttle(initialize, 100));
