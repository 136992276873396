import { notifySuccess, notifyError } from './notify';
import { flash } from './rails';

export default function () {
  const messages = flash();

  // NOTE: Railsのエラーページ等で存在しないケースがある
  if (!messages) {
    return;
  }

  Object.keys(messages).forEach((name) => {
    const message = messages[name];
    if (typeof message !== 'string') return;
    if (name === 'notice') {
      notifySuccess(message);
    } else {
      notifyError(message);
    }
  });
}
