import $ from 'jquery';
import waitForImageToLoad from '../../shared/lib/wait_for_Image_to_load';
import { notifySuccess } from '../../shared/lib/notify';

let timer = null;
let timeover = false;
let startTime;

const colorStringToArray = color => color
  .replace('rgb(', '')
  .replace(')', '')
  .split(',');

const setupTimer = ($bar, inputElementNameBase) => {
  const startColor = $bar.css('background-color').toString();
  const startColors = colorStringToArray(startColor);
  const middleColors = [240, 173, 78];
  const endColors = [217, 83, 79];
  const bgColors = [];
  const timerMillisecond = 1000;
  const limitSecond = $('.js-answer-history').data('limitSecond');

  setInterval(() => {
    const leftSecond = $bar.data('leftSecond') - (timerMillisecond / 1000); // prettier-ignore
    $bar.data('leftSecond', leftSecond);
    const progressRate = (limitSecond - leftSecond) / limitSecond;
    $bar.width(`${100 - (progressRate * 100)}%`); // prettier-ignore

    if (leftSecond > limitSecond / 2) {
      $.each(startColors, (index, number) => {
        bgColors[index] = Math.round(
          parseInt(number, 10)
            + ((middleColors[index] - parseInt(number, 10)) * (progressRate * 2)), // prettier-ignore
        );
      });
    } else {
      $.each(middleColors, (index, number) => {
        bgColors[index] = Math.round(
          parseInt(number, 10)
            + ((endColors[index] - parseInt(number, 10))
              * (progressRate - 0.5) * 2), // prettier-ignore
        );
      });
    }

    $bar.css('background-color', `rgb(${bgColors.join(',')})`);

    if (leftSecond <= 0) {
      $(`input[name="${inputElementNameBase}[answers][]"]:checked`).prop('checked', false);
      $(`input[name^="${inputElementNameBase}[answers]"]`).attr('disabled', true);
      $('.js-answer-timeover').removeClass('hidden');
      $('.js-btn-submit').removeClass('hidden');
      $bar.removeClass('active');
      timeover = true;
      clearInterval(timer);
    }
  }, timerMillisecond);
};

const disabledSubmit = () => $('.js-btn-submit').prop('disabled');

const stopProgressBar = (_timer) => {
  clearInterval(_timer);
  return $('.js-answer-progress-bar').removeClass('active');
};

const forbiddenDuplicateSubmit = (_timer) => {
  if (disabledSubmit()) {
    return;
  }

  $('.js-btn-submit').prop('disabled', true);
  stopProgressBar(_timer);
  $('.js-answer-form').submit();
};

const updateLeftCount = (input) => {
  const answer = input.closest('.js-answer');
  if (!answer) {
    return;
  }

  const answerLength = parseInt(answer.dataset.length, 10);
  const inputLength = input.value.length;
  const leftCountElement = answer.querySelector('.js-answer-left-count');
  leftCountElement.textContent = answerLength - inputLength;
  leftCountElement.classList.toggle('text-info', answerLength === inputLength);
  leftCountElement.classList.toggle('text-danger', answerLength !== inputLength);
};

const initialize = (inputElementNameBase) => {
  const ENTER_KEY = 13;
  const TAB_KEY = 9;
  const $bar = $('.js-answer-progress-bar');

  if ($bar.length > 0) {
    // NOTE: 以前はturbolinksを有効化しているためwindow loadイベントを使えないためこの実装になっていた。
    //       最終的には削除できるかも
    waitForImageToLoad(document.querySelector('.js-answer-history')).then(() => {
      timer = setupTimer($bar, inputElementNameBase);
      startTime = Date.now();
    });
  }

  $('body').on('keydown', (event) => {
    if ($('#js-question-inquiries-modal').is(':visible')) return;

    let $nextCheckBox;
    let $checked;
    const $nextBtn = $('.js-btn-next');
    const $submit = $('.js-btn-submit');

    if (event.which === ENTER_KEY) {
      if ($nextBtn.length && !disabledSubmit()) {
        $('.js-btn-next')[0].click();
      }

      if ($submit.length && !disabledSubmit()) {
        stopProgressBar(timer);
        $('#new_answer_history').submit();
      }
    } else if (event.which === TAB_KEY && !timeover && $('.js-answer-radio-label').length > 0) {
      $checked = $(`input[name="${inputElementNameBase}[answers][]"]:checked`);
      $checked.prop('checked', false);
      $nextCheckBox = $checked
        .parent()
        .next()
        .find('input');

      if ($nextCheckBox.length) {
        $nextCheckBox.prop('checked', true);
      } else {
        $(`input[name="${inputElementNameBase}[answers][]"]:first`).prop('checked', true);
      }

      event.preventDefault();
    }
  });

  $('.js-answer-radio-label').on('click', (event) => {
    // NOTE: これがないとチェックボックスデフォルト動作によってチェックが外れてしまう
    event.preventDefault();

    const { currentTarget } = event;
    if (timeover) {
      return;
    }

    const $label = $(currentTarget);
    const $form = $label.closest('form');
    $form.find('input[type="checkbox"]:checked').prop('checked', false);
    $label
      .parent()
      .find('input')
      .prop('checked', true);

    forbiddenDuplicateSubmit(timer);
  });

  $('.js-btn-submit').on('click', (event) => {
    forbiddenDuplicateSubmit(timer);
    return event.preventDefault();
  });

  const fadeOutDelayMillisecond = 1000;

  return setTimeout(() => $('.js-answer-combo').fadeOut('slow'), fadeOutDelayMillisecond);
};

const $document = $(document);

$document.on('turbo:load', () => {
  const $body = $('body');

  let isInitializeRequired = false;
  let inputElementNameBase;
  if ($body.hasClass('answer_histories') || $body.hasClass('weakness_training_answers')) {
    isInitializeRequired = true;
    inputElementNameBase = 'answer_history';
  } else if ($body.hasClass('knock_answer_histories')) {
    isInitializeRequired = true;
    inputElementNameBase = 'knock_answer_history';
  }

  if (isInitializeRequired) {
    initialize(inputElementNameBase);
  }

  Array.from(document.querySelectorAll('.js-answer-text')).forEach(updateLeftCount);
});

$document.on('keyup keypress change', '.js-answer-text', ({ currentTarget }) => {
  updateLeftCount(currentTarget);
});

// NOTE: 回答にかかった時間をブラウザ側で計算して送信している
$document.on('submit', '.js-answer-form', ({ currentTarget }) => {
  // NOTE: 弱点克服トレーニングは対象外
  const answerHistoryElement = document.querySelector('.js-answer-history');
  if (!answerHistoryElement) {
    return;
  }
  const endTime = Date.now();
  const limitSeconds = parseInt(answerHistoryElement.dataset.limitSecond, 10);
  const seconds = startTime ? Math.floor((endTime - startTime) / 1000) : 0;

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'answer_history[seconds]';
  input.value = Math.min(seconds, limitSeconds);
  currentTarget.appendChild(input);
});

$(document).on('ajax:success', '.js-question-inquiries-form', ({ currentTarget }) => {
  const textarea = currentTarget.querySelector('textarea');
  textarea.value = '';
  $('#js-question-inquiries-modal').modal('hide');
  notifySuccess('送信しました。');
});
