import $ from 'jquery';

const showModal = ({ currentTarget, relatedTarget }) => {
  const $modal = $(currentTarget);
  const $link = $(relatedTarget);
  const $loading = $modal.find('.js-loading');
  const $modalBody = $modal.find('.js-modal-body');

  $loading.show();
  $modalBody.html('');

  $.ajax({
    type: 'GET',
    url: $link.data('url'),
    cache: false,
  }).done((html) => {
    $loading.hide();
    $modalBody.html(html);
  });
};

$(document).on('show.bs.modal', '#js-point-history', showModal);
$(document).on('show.bs.modal', '#js-team-level-point-history', showModal);
