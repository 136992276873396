import $ from 'jquery';

const initialize = () => {
  const waitMillisecond = 2000;

  setTimeout(
    () => $('.js-topic-progress-bar').each(() => {
      const $progressBar = $(this).find('.progress-bar');
      const afterWidth = $(this).data('progress');

      $progressBar.animate({
        width: afterWidth,
      });
    }),
    waitMillisecond,
  );

  $('.js-custom-fade-in').addClass('visible');

  $('.js-note-toggle').on('click', ({ currentTarget }) => {
    $(currentTarget).closest('.progress_topic').find('.progress__topic-progress-topic-note').toggle();
  });
};

$(document).on('turbo:load', () => {
  if ($('.progresses').length > 0) {
    initialize();
  }
});
