import $ from 'jquery';
import { notifySuccess } from '../lib/notify';

const $document = $(document);

const resetForm = (form) => {
  const textarea = form.querySelector('textarea');
  textarea.value = '';
  textarea.style.height = '';
};

const replaceHtml = (selector, html) => {
  const element = document.querySelector(selector);
  element.innerHTML = html;
};

$document.on('ajax:success', '.js-tenant-entry-administrative-update-form', () => {
  notifySuccess('登録しました。');
});

$document.on('ajax:success', '.js-entry-comment-form', ({ currentTarget }, html) => {
  resetForm(currentTarget);
  replaceHtml(currentTarget.dataset.target, html);
  notifySuccess('投稿しました。');
});

$document.on('ajax:success', '.js-entry-comment-delete-link', ({ currentTarget }, html) => {
  replaceHtml(currentTarget.dataset.target, html);
  notifySuccess('削除しました。');
});

$document.on('focus', '.js-entry-comment-form textarea', ({ currentTarget }) => {
  const $textarea = $(currentTarget);
  $textarea.removeAttr('rows');
  $textarea
    .closest('form')
    .find('.hidden')
    .removeClass('hidden');
});
