const durationSecondsToString = (durationSeconds) => {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = Math.floor(durationSeconds % 60);

  const hoursString = hours > 0 ? `${hours}時間 ` : '';
  const minutesString = minutes > 0 ? `${minutes}分 ` : '';
  const secondsString = `${seconds}秒`;

  return `${hoursString}${minutesString}${secondsString}`;
};

export { durationSecondsToString };
