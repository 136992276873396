import $ from 'jquery';
import toastr from 'toastr';

const notifySuccess = (message, title = '') => {
  toastr.success(message, title, { timeOut: 5000 });
};

const notifyError = (message, title = '') => {
  toastr.error(message, title, { timeOut: 20000 });
};

const notifyErrors = (errors) => {
  // htmlエスケープの為に一旦domで組み立てる
  const $ul = $('<ul></ul>');
  const $errors = errors.map(error => $('<li></li>').text(error));
  $ul.append($errors);
  notifyError($ul.prop('outerHTML'), 'エラーが発生しました。');
};

export { notifySuccess, notifyError, notifyErrors };
