import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import TeamMessage from './team_message';

export default class TeamMessages extends React.Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const { scrollHeight } = this.element;
    const height = this.element.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.element.scrollTop = Math.max(maxScrollTop, 0);
  }

  render() {
    const teamMessages = this.props.messages
      .valueSeq()
      .map(message => (
        <TeamMessage
          key={message.id}
          message={message}
          participations={this.props.participations}
          isSelf={message.participation.id === this.props.currentParticipationId}
          currentParticipationId={this.props.currentParticipationId}
          dispatch={this.props.dispatch}
          topicLinkDisabled={this.props.topicLinkDisabled}
        />
      ));
    return (
      <div
        className="team-messages"
        ref={(element) => {
          this.element = element;
        }}
      >
        {teamMessages}
      </div>
    );
  }
}

TeamMessages.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(Iterable).isRequired,
  participations: PropTypes.instanceOf(Iterable).isRequired,
  currentParticipationId: PropTypes.number.isRequired,
  topicLinkDisabled: PropTypes.bool.isRequired,
};
