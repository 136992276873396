import $ from 'jquery';
import { notifySuccess } from '../lib/notify';
import polyglot from '../../application/lib/polyglot';

const $document = $(document);

const resetForm = (form) => {
  const textarea = form.querySelector('input[type="text"]');
  textarea.value = '';
  textarea.style.height = '';
};

$document.on('ajax:success', '.js-entry-team-message-form', ({ currentTarget }) => {
  resetForm(currentTarget);
  currentTarget.querySelector('submit');
  notifySuccess(polyglot.t('js.general.posted'));
});

$document.on('click', '.js-viewing-history__note-form-registration-button', () => {
  document.querySelector('#js-viewing-history__note-form').submit();
});

$document.on('change', '.js-viewing-history__tabs-new-note-images', ({ currentTarget }) => {
  const fileInputForm = currentTarget;
  const maxFilesNumber = Number(fileInputForm.dataset.maxFilesNumber);
  const maxFileSizeByte = Number(fileInputForm.dataset.maxFileSizeByte);
  const { maxFileSizeLabel } = fileInputForm.dataset;

  if (fileInputForm.files.length > maxFilesNumber) {
    // eslint-disable-next-line no-alert
    alert(polyglot.t('js.viewing_history.training_note.note_images_max_number.alert', { max_number: maxFilesNumber }));
    fileInputForm.value = '';
  } else if (Array.from(fileInputForm.files).filter(file => file.size > maxFileSizeByte).length > 0) {
    // eslint-disable-next-line no-alert
    alert(polyglot.t('js.viewing_history.training_note.note_image_max_file_size.description', { max_file_size: maxFileSizeLabel }));
    fileInputForm.value = '';
  }
});
